import React from 'react'
import classname from 'classnames/bind'
import styles from './main.module.css'
import { useSelector } from 'react-redux'

// components
import SideBar from '../layout/sidebar'
import Modal from '../component/Modal'

// container
import ModelMenuContainer from '../containers/menu/model-menu-container'
import EditorContainer from '../containers/editor/editor-container'
import ColorContainer from '../containers/color/color-container'
import ColorPickerContainer from '../containers/color-picker/color-picker-container'
import ChangeModelModal from '../containers/modal/change-model-modal'

// Component
import Layout from '../layout/layout'

const cn = classname.bind()

const Main = (props) => {
    const { colorPickerActive } = useSelector((state) => state.color)
    const { modal, modalItem } = useSelector((state) => state.customizer)

    return (
        <Layout>
            <Modal show={colorPickerActive.active} zIndex={999999}>
                <ColorPickerContainer />
            </Modal>
            <Modal show={modal != null}>
                {modal == 'changeModel' && <ChangeModelModal modalItem={modalItem} />}
            </Modal>
            <div className={cn(styles.editorContainer, 'relative flex-1')}>
                <EditorContainer />
                <ColorContainer />
            </div>
            <SideBar>
                <ModelMenuContainer />
            </SideBar>
        </Layout>
    )
}

export default Main
