import { CUSTOMIZER_CHANGE_BREAKPOINT, SHOW_MODAL, SHOW_MODEL_MOBILE_MENU } from '../actions'
import update from 'immutability-helper'

const INITIAL_STATE = {
    breakpoint: 'default',
    modal: null,
    modalItem: null,
    modelMobileMenu: false,
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CUSTOMIZER_CHANGE_BREAKPOINT:
            return update(state, {
                breakpoint: {
                    $set: action.payload,
                },
            })
        case SHOW_MODAL:
            return update(state, {
                modal: {
                    $set: action.modalType,
                },
                modalItem: {
                    $set: action.modalItem,
                },
            })
        case SHOW_MODEL_MOBILE_MENU:
            return update(state, {
                modelMobileMenu: {
                    $set: action.value != null ? action.value : !state.modelMobileMenu,
                },
            })
        default:
            return state
    }
}
