import React from 'react'
import classname from 'classnames/bind'
import { useSelector, useDispatch } from 'react-redux'

import { ReactComponent as IconUp } from '../assets/icons/arrow-up.svg'
import { ReactComponent as IconDown } from '../assets/icons/arrow-down.svg'

import { mediaMinWidth } from '../utils/breakpoints'
import { showModelMobileMenu } from '../store/customizer/customizer-actions'

const cn = classname.bind()

const SideBar = ({ children }) => {
    const dispatch = useDispatch()
    const { breakpoint, modelMobileMenu } = useSelector((state) => state.customizer)

    let containerClass = cn('relative flex-shrink-0 w-80')
    let innerClass = cn('overflow-scroll')

    if (!mediaMinWidth(breakpoint).lg) {
        containerClass = cn(
            'absolute w-full bottom-0 left-0 bg-gray-200 border-t border-gray-300',
            'border-solid h-64 transform transition-transform',
            !modelMobileMenu ? 'translate-y-64' : 'translate-y-0'
        )
        innerClass = cn()
    }

    return (
        <div className={cn(containerClass)}>
            <div
                className={cn(
                    'absolute px-4 py-2 lg:hidden border-gray-300 border-solid',
                    'bg-gray-200 border rounded-sm font-bold flex flex-row items-center'
                )}
                style={{ top: '-43px', left: 0 }}
                onClick={() => dispatch(showModelMobileMenu())}
            >
                Model <span className={'ml-2'}>{!modelMobileMenu ? <IconDown /> : <IconUp />}</span>
            </div>
            <div className={cn('absolute inset-0 lg:px-8', innerClass)}>{children}</div>
        </div>
    )
}

export default SideBar
