import {
    SET_COLORS_ACTIVE,
    SET_COLOR_POSITION,
    ADD_COLOR_PICKER,
    SET_COLOR_PICKER_ACTIVE,
    SET_COLOR_DELETEABLE,
    REMOVE_COLOR_PICKER,
    REMOVE_COLOR_BORDER_PICKER,
    SET_COLORS_BORDER_ACTIVE,
    SET_COLOR_BORDER_DELETEABLE,
} from '../actions'

export const setColorActive = (color) => {
    return {
        type: SET_COLORS_ACTIVE,
        color,
    }
}

export const setColorBorderActive = (color) => {
    return {
        type: SET_COLORS_BORDER_ACTIVE,
        color,
    }
}

export const setColorPosition = ({ top, left }) => {
    return {
        type: SET_COLOR_POSITION,
        top,
        left,
    }
}

export const addColorPicker = (color, colorType) => {
    return {
        type: ADD_COLOR_PICKER,
        color,
        colorType,
    }
}

export const setColorPickerActive = (colorType) => {
    return {
        type: SET_COLOR_PICKER_ACTIVE,
        colorType,
    }
}

export const setColorDeletable = () => {
    return {
        type: SET_COLOR_DELETEABLE,
    }
}

export const setColorBorderDeletable = () => {
    return {
        type: SET_COLOR_BORDER_DELETEABLE,
    }
}

export const removeColorPicker = (colorIndex) => {
    return {
        type: REMOVE_COLOR_PICKER,
        colorIndex,
    }
}

export const removeColorBorderPicker = (colorIndex) => {
    return {
        type: REMOVE_COLOR_BORDER_PICKER,
        colorIndex,
    }
}
