export const colorData = [
    '#7D7D7D',
    '#FBFBFB',
    '#E6A38C',
    '#B9CC7B',
    '#83B7CF',
    '#C9DCE0',
    '#B4B3B1',
    '#FDB87D',
    '#FBE27C',
    '#FBAFD6',
    '#6A5A8B',
]

export const colorBorderData = ['#6A5A8B', '#FBAFD6', '#FBE27C', '#FDB87D', '#B4B3B1', '#C9DCE0']
