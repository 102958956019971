import React from 'react'
import classname from 'classnames/bind'

const cn = classname.bind()

const Center = ({ children, hide }) => {
    return (
        <div
            className={cn(
                'absolute',
                'inset-0',
                'flex',
                'items-center',
                'justify-center',
                hide ? 'hidden' : 'block'
            )}
        >
            {children}
        </div>
    )
}

export default Center
