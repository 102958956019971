import { CUSTOMIZER_CHANGE_BREAKPOINT, SHOW_MODAL, SHOW_MODEL_MOBILE_MENU } from '../actions'

export const changeThemeBreakPoint = (mediaQuery) => {
    return {
        type: CUSTOMIZER_CHANGE_BREAKPOINT,
        payload: mediaQuery,
    }
}

export const showModal = ({ modalType, modalItem }) => {
    return {
        type: SHOW_MODAL,
        modalType,
        modalItem,
    }
}

export const showModelMobileMenu = (value) => {
    return {
        type: SHOW_MODEL_MOBILE_MENU,
        value,
    }
}
