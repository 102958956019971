import {
    SET_COLORS_ACTIVE,
    SET_COLOR_POSITION,
    ADD_COLOR_PICKER,
    REMOVE_COLOR_PICKER,
    SET_COLOR_PICKER_ACTIVE,
    SET_COLOR_DELETEABLE,
    SET_COLORS_BORDER_ACTIVE,
    SET_COLOR_BORDER_DELETEABLE,
    REMOVE_COLOR_BORDER_PICKER,
} from '../actions'

import { colorData, colorBorderData } from '../../data/color-data'

import update from 'immutability-helper'

const INITIAL_STATE = {
    colors: colorData,
    colorsBorder: colorBorderData,
    colorActive: null,
    colorBorderActive: null,
    colorPosition: {
        top: null,
        left: null,
    },
    colorPicker: '#ffffff',
    colorPickerActive: {
        active: false,
        type: 'box',
    },
    colorDeletable: false,
    colorBorderDeletable: false,
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_COLORS_ACTIVE:
            return update(state, {
                colorActive: {
                    $set: action.color,
                },
            })
        case SET_COLORS_BORDER_ACTIVE:
            return update(state, {
                colorBorderActive: {
                    $set: action.color,
                },
            })
        case SET_COLOR_POSITION:
            return update(state, {
                colorPosition: {
                    $set: {
                        top: action.top,
                        left: action.left,
                    },
                },
            })
        case ADD_COLOR_PICKER:
            switch (action.colorType) {
                case 'box':
                    return update(state, {
                        colorPicker: {
                            $set: action.color,
                        },
                        colors: {
                            $push: [action.color],
                        },
                    })
                case 'border':
                    return update(state, {
                        colorPicker: {
                            $set: action.color,
                        },
                        colorsBorder: {
                            $push: [action.color],
                        },
                    })
                default:
                    return
            }
        case REMOVE_COLOR_PICKER:
            return update(state, { colors: { $splice: [[action.colorIndex, 1]] } })
        case REMOVE_COLOR_BORDER_PICKER:
            return update(state, { colorsBorder: { $splice: [[action.colorIndex, 1]] } })
        case SET_COLOR_PICKER_ACTIVE:
            return update(state, {
                colorPickerActive: {
                    active: {
                        $set: !state.colorPickerActive.active,
                    },
                    type: {
                        $set: action.colorType,
                    },
                },
            })
        case SET_COLOR_DELETEABLE:
            return update(state, {
                colorDeletable: {
                    $set: !state.colorDeletable,
                },
            })
        case SET_COLOR_BORDER_DELETEABLE:
            return update(state, {
                colorBorderDeletable: {
                    $set: !state.colorBorderDeletable,
                },
            })
        default:
            return state
    }
}
