// component
import Box from '../component/Box'
import { boxType } from './box-type'
import classname from 'classnames/bind'

const cn = classname.bind()

export const modelBuilder = (boxTable, size, props) => {
    let dataIndex = 0
    return boxTable.map((row, rowIndex) => {
        return (
            <div className={cn('flex flex-rowjustify-center')} key={rowIndex}>
                {row.map((col, colIndex) => {
                    dataIndex += 1
                    return (
                        <Box
                            {...props}
                            key={colIndex}
                            boxId={props.modelId + `box-${rowIndex}-${colIndex}`}
                            dataIndex={dataIndex}
                            size={boxType(col, size)}
                            borderWidth={props.border.width}
                            borderColor={props.border.color}
                        />
                    )
                })}
            </div>
        )
    })
}
