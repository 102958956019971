import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'

import './assets/style/global.css'

import { Provider } from 'react-redux'
import { configureStore } from './store/store'
import { PersistGate } from 'redux-persist/integration/react'

const redux = configureStore()

ReactDOM.render(
    <React.StrictMode>
        <Provider store={redux.store}>
            <PersistGate loading={null} persistor={redux.persistor}>
                <App />
            </PersistGate>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
