import {
    ADD_MODEL,
    CLEAR_MODEL,
    SET_BOX_ACTIVE,
    SET_BOX_POSITION,
    SET_BOX_COLOR,
    CHANGE_MODEL,
    SET_MODEL_BORDER_WITH,
    SET_MODEL_BORDER_COLOR,
} from '../actions'

import { modelData } from '../../data/model-data'
import update from 'immutability-helper'

const INITIAL_STATE = {
    models: modelData,
    layout: [],
    boxActive: {
        modelId: null,
        modelIndex: null,
        boxId: null,
        boxIndex: null,
    },
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ADD_MODEL:
            return update(state, {
                layout: {
                    $push: [action.data],
                },
            })
        case CHANGE_MODEL:
            return update(state, {
                layout: {
                    $set: [action.data],
                },
            })
        case CLEAR_MODEL:
            return update(state, {
                layout: {
                    $set: [],
                },
                boxActive: {
                    $set: {
                        modelId: null,
                        modelIndex: null,
                        boxId: null,
                        boxIndex: null,
                    },
                },
            })
        case SET_BOX_ACTIVE:
            return update(state, {
                boxActive: {
                    $set: {
                        modelId: action.modelId,
                        modelIndex: action.modelIndex,
                        boxId: action.boxId,
                        boxIndex: action.boxIndex,
                    },
                },
            })
        case SET_BOX_POSITION:
            return update(state, {
                layout: {
                    [action.index]: {
                        style: {
                            top: {
                                $set: action.top,
                            },
                            left: {
                                $set: action.left,
                            },
                        },
                    },
                },
            })
        case SET_BOX_COLOR:
            return update(state, {
                layout: {
                    [action.index]: {
                        color: {
                            [action.boxIndex]: {
                                $set: action.color,
                            },
                        },
                    },
                },
            })
        case SET_MODEL_BORDER_WITH:
            return update(state, {
                layout: {
                    [action.modelIndex]: {
                        border: {
                            width: {
                                $set: action.borderWidth,
                            },
                        },
                    },
                },
            })
        case SET_MODEL_BORDER_COLOR:
            return update(state, {
                layout: {
                    [action.modelIndex]: {
                        border: {
                            color: {
                                $set: action.borderColor,
                            },
                        },
                    },
                },
            })
        default:
            return state
    }
}
