import { combineReducers } from 'redux'
import model from './models/model-reducer'
import color from './color/color-reducers'
import customizer from './customizer/customizer-reducer'

const appReducer = combineReducers({
    model,
    color,
    customizer,
})

export default appReducer
