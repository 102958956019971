import { v4 as uuidv4 } from 'uuid'

export const newModelData = (item, x, y) => {
    let newData = { ...item }
    let modelId = uuidv4()

    newData.style = {
        ...newData.style,
        top: y != null ? y : 100,
        left: x != null ? x : 100,
    }

    return {
        modelId,
        ...newData,
    }
}
