import React from 'react'
import classname from 'classnames/bind'
import Modal from '../../component/Modal'

// store
import { useSelector, useDispatch } from 'react-redux'
import { mediaMinWidth } from '../../utils/breakpoints'
import { setBoxActive } from '../../store/models/model-actions'
import { setColorActive } from '../../store/color/color-actions'

// component
import BoxColorContainer from './box/box-color-container'
import BorderContainer from './border/border-container'

const cn = classname.bind()

const ColorContainer = (props) => {
    const dispatch = useDispatch()
    const { boxActive } = useSelector((state) => state.model)
    const { colorPosition } = useSelector((state) => state.color)
    const { breakpoint } = useSelector((state) => state.customizer)

    const onClickOutSide = (e) => {
        e.stopPropagation()
        dispatch(setBoxActive({ modelId: null, modelIndex: null, boxId: null, boxIndex: null }))
        dispatch(setColorActive(null))
    }

    let mobileClass = cn('w-max')
    let style = {
        top: colorPosition.top,
        left: colorPosition.left,
    }

    if (!mediaMinWidth(breakpoint).lg) {
        style = {}
        mobileClass = cn('w-full', 'bottom-0')
    }

    return (
        <Modal show={boxActive.boxId != null} opacity={25} onClick={onClickOutSide}>
            <div
                className={cn(
                    'color-container absolute z-50 lg:w-96 p-6 shadow-lg bg-white rounded-lg',
                    mobileClass
                )}
                style={style}
                onClick={(e) => {
                    e.stopPropagation()
                    return
                }}
            >
                <BoxColorContainer />
                <BorderContainer />
            </div>
        </Modal>
    )
}

export default ColorContainer
