import React from 'react'
import classname from 'classnames/bind'

// assets
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg'

const cn = classname.bind()

const ColorViewContainer = ({ colors, colorActive, isDeletable, onClickDelete, onClickColor }) => {
    return colors.map((data, index) => {
        return (
            <div
                key={index}
                className={cn(
                    'relative m-1 rounded-md cursor-pointer',
                    colorActive === data ? 'color-active' : 'color-not-active'
                )}
                style={{ width: 40, height: 40 }}
            >
                <div
                    className={cn(
                        'absolute w-6 h-6 flex flex-1 items-center justify-center rounded-full overflow-hidden -top-2 -left-2',
                        isDeletable ? 'block' : 'hidden'
                    )}
                    onClick={(e) => onClickDelete(e, index)}
                >
                    <div
                        className={cn('absolute w-full h-full bg-red-600 z-auto rounded-full')}
                    ></div>
                    <div className={cn('relative')}>
                        <TrashIcon />
                    </div>
                </div>
                <div
                    className={cn('w-full', 'h-full', 'border', 'border-gray-400', 'rounded-md')}
                    style={{ backgroundColor: data }}
                    onClick={(e) => onClickColor(e, data)}
                ></div>
            </div>
        )
    })
}

export default ColorViewContainer
