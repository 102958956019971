import React from 'react'
import classname from 'classnames/bind'

// utils
import { mediaMinWidth } from '../../utils/breakpoints'
import { modelBuilder } from '../../utils/model-builder'

const cn = classname.bind()

const ModelViewContainer = (props) => {
    let modelStyle = {}

    if (!mediaMinWidth(props.breakpoint).lg) {
        modelStyle = {
            transform: `translate(0, 0)`,
        }
    }

    return (
        <div
            className={cn('flex flex-col items-start cursor-move w-max', `model-${props.modelId}`)}
            style={modelStyle}
        >
            {modelBuilder(props.boxTable, 'md', props)}
            <div className={cn('text-center', 'my-4', 'w-full', 'text-xl')}>{props.name}</div>
        </div>
    )
}

export default ModelViewContainer
