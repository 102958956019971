import React, { useState, useEffect } from 'react'
import classname from 'classnames/bind'
import Moveable from 'react-moveable'

// store
import { useDispatch, useSelector } from 'react-redux'

// container
import ModelViewContainer from './model-view-container'

// store
import { setBoxActive, setBoxPosition } from '../../store/models/model-actions'
import { setColorPosition, setColorActive } from '../../store/color/color-actions'
import { showModelMobileMenu } from '../../store/customizer/customizer-actions'

// utils
import { mediaMinWidth } from '../../utils/breakpoints'

const cn = classname.bind()

const ModelContainer = (props) => {
    const dispatch = useDispatch()

    const { boxActive, borderWidth } = useSelector((state) => state.model)
    const { breakpoint, modelMobileMenu } = useSelector((state) => state.customizer)

    const [position, setPosition] = useState({
        top: props.style.top,
        left: props.style.left,
    })
    const [target, setTarget] = useState()
    const [frame, setFrame] = useState({
        translate: [0, 0],
    })

    useEffect(() => {
        setTarget(document.querySelector(`.model-${props.modelId}`))
    }, [breakpoint])

    const onClickBox = (e) => {
        dispatch(
            setBoxActive({
                modelId: props.modelId,
                modelIndex: props.index,
                boxId: e.inputTarget.id,
                boxIndex: e.inputTarget.dataset.index,
            })
        )
        dispatch(
            setColorPosition({ top: e.inputEvent.clientY + 20, left: e.inputEvent.clientX + 20 })
        )
        dispatch(setColorActive(props.color[e.inputTarget.dataset.index]))
    }

    const onCLickBoxSm = (e, id, index) => {
        if (mediaMinWidth(breakpoint).lg) {
            return
        }

        dispatch(
            setBoxActive({
                modelId: props.modelId,
                modelIndex: props.index,
                boxId: id,
                boxIndex: index,
            })
        )

        dispatch(setColorPosition({ top: e.clientY + 20, left: e.clientX + 20 }))

        if (!modelMobileMenu) {
            dispatch(showModelMobileMenu())
        }
    }

    let containerClass = cn('absolute')
    let containerStyle = styles(position)

    if (!mediaMinWidth(breakpoint).lg) {
        containerClass = cn('relative')
        containerStyle = {
            transform: `translate(0, 0)`,
        }
    }

    return (
        <div className={cn(containerClass, 'w-max', 'rax-container')} style={containerStyle}>
            <ModelViewContainer
                {...props}
                activeModelId={boxActive.modelId}
                activeBoxId={boxActive.boxId}
                onClick={onCLickBoxSm}
                borderWidth={borderWidth}
                breakpoint={breakpoint}
            />
            <Moveable
                target={target}
                draggable={mediaMinWidth(breakpoint).lg}
                throttleDrag={0}
                startDragRotate={0}
                throttleDragRotate={0}
                zoom={1}
                origin={false}
                padding={{ left: 0, top: 0, right: 0, bottom: 0 }}
                onDragStart={({ set }) => {
                    set(frame.translate)
                }}
                onDrag={({ target, beforeTranslate }) => {
                    target.style.transform = `translate(${beforeTranslate[0]}px, ${beforeTranslate[1]}px)`
                }}
                onDragEnd={({ lastEvent }) => {
                    const position = target.getBoundingClientRect()
                    if (lastEvent) {
                        frame.translate = lastEvent.beforeTranslate
                        dispatch(
                            setBoxPosition({
                                index: props.index,
                                top: position.top,
                                left: position.left,
                            })
                        )
                    }
                }}
                onClick={onClickBox}
            />
        </div>
    )
}

const styles = (style) => {
    return {
        top: style.top,
        left: style.left,
    }
}

export default ModelContainer
