import React from 'react'
import classname from 'classnames/bind'
import { mediaMinWidth } from '../utils/breakpoints'

import { useSelector } from 'react-redux'

const cn = classname.bind()

const Box = ({
    boxId,
    dataIndex,
    color,
    size,
    onClick,
    activeModelId,
    activeBoxId,
    modelId,
    borderWidth,
    borderColor,
    isMenu,
}) => {
    const { breakpoint } = useSelector((state) => state.customizer)

    let sizes = null
    let mobileClass = ''
    let onClickBox = () => {}
    let isActive = activeModelId === modelId && activeBoxId === boxId
    let colorStyle = color != null && dataIndex != null ? color[dataIndex] : '#ffffff'
    let borderWidthStyle = borderWidth && !isMenu ? borderWidth : 1
    let borderColorStyle = borderColor && !isMenu ? borderColor : '#000000'
    let boxHandTop = 8

    let isDoubleColumn = false

    if (onClick != null) {
        onClickBox = (e) => onClick(e, boxId, dataIndex)
    }

    if (!activeModelId) {
        isActive = false
    }

    switch (size) {
        case 'sm':
            sizes = {
                width: 60,
                height: 30,
            }
            break
        case 'sm1-1/2':
            sizes = {
                width: 60,
                height: 30,
            }
            isDoubleColumn = true
            break
        case 'sm1/2':
            sizes = {
                width: 30,
                height: 30,
            }
            break
        case 'sm2/3':
            sizes = {
                width: 40,
                height: 30,
            }
            break
        case 'sm3/4':
            sizes = {
                width: 45,
                height: 30,
            }
            break
        case 'md':
            sizes = {
                width: 180,
                height: 75,
            }
            boxHandTop = 15
            break
        case 'md1-1/2':
            sizes = {
                width: 180,
                height: 75,
            }
            isDoubleColumn = true
            boxHandTop = 15
            break
        case 'md1/2':
            sizes = {
                width: 90,
                height: 75,
            }
            boxHandTop = 15
            break
        case 'md2/3':
            sizes = {
                width: 120,
                height: 75,
            }
            boxHandTop = 15
            break
        case 'md3/4':
            sizes = {
                width: 135,
                height: 75,
            }
            boxHandTop = 15
            break
        default:
            sizes = {
                width: 180,
                height: 75,
            }
            break
    }

    if (!mediaMinWidth(breakpoint).lg && size.includes('md')) {
        sizes = {
            width: sizes.width * (2 / 5),
            height: sizes.height * (2 / 5),
        }
    } else if (size.includes('md')) {
        sizes = {
            width: sizes.width * (2 / 3),
            height: sizes.height * (2 / 3),
        }
    }

    const thisStyle = styles(
        sizes,
        size,
        colorStyle,
        borderColorStyle,
        parseInt(borderWidthStyle),
        boxHandTop
    )

    return (
        <div
            id={boxId}
            className={cn(
                'relative',
                'box',
                'border-gray-900',
                isActive ? 'box-active' : 'box-notactive',
                'cursor-default',
                'border-solid',
                mobileClass
            )}
            data-index={dataIndex}
            style={thisStyle.boxContainer}
            onClick={onClickBox != null ? onClickBox : () => {}}
        >
            {isDoubleColumn ? (
                <div
                    className={cn('absolute inset-0 bg-black')}
                    style={thisStyle.isDoubleColumn}
                ></div>
            ) : (
                <div
                    className={cn('absolute border border-solid  border-gray-900 bg-black')}
                    style={thisStyle.boxHands}
                ></div>
            )}

            <div className={cn('absolute')} style={thisStyle.borderLeft}></div>
            <div className={cn('absolute')} style={thisStyle.borderTop}></div>
            <div className={cn('absolute')} style={thisStyle.borderBottom}></div>
            <div className={cn('absolute')} style={thisStyle.borderRight}></div>
        </div>
    )
}

const styles = (sizes, sizeType, color, borderColor, borderWidth, boxHandTop) => {
    return {
        boxHands: {
            top: boxHandTop,
            left: 0,
            right: 0,
            margin: 'auto',
            width: sizes.width / 3,
            height: sizes.height / 10,
        },
        isDoubleColumn: {
            margin: 'auto',
            width: sizes.width,
            height: sizeType.includes('sm') ? 1 : `${borderWidth}px`,
        },
        boxContainer: {
            backgroundColor: color ? color : '#ffffff',
            ...sizes,
        },
        borderLeft: {
            width: `${borderWidth}px`,
            height: `${sizes.height + borderWidth}px`,
            left: `-${borderWidth / 2}px`,
            backgroundColor: borderColor,
            top: `50%`,
            bottom: `50%`,
            transform: 'translateY(-50%)',
        },
        borderTop: {
            height: `${borderWidth}px`,
            width: `${sizes.width + borderWidth}px`,
            top: `-${borderWidth / 2}px`,
            backgroundColor: borderColor,
            left: `50%`,
            right: `50%`,
            transform: 'translateX(-50%)',
        },
        borderBottom: {
            height: `${borderWidth}px`,
            width: `${sizes.width + borderWidth}px`,
            bottom: `-${borderWidth / 2}px`,
            backgroundColor: borderColor,
            left: `50%`,
            right: `50%`,
            transform: 'translateX(-50%)',
        },
        borderRight: {
            width: `${borderWidth}px`,
            height: `${sizes.height + borderWidth}px`,
            right: `-${borderWidth / 2}px`,
            backgroundColor: borderColor,
            top: `50%`,
            bottom: `50%`,
            transform: 'translateY(-50%)',
        },
    }
}

export default Box
