import React from 'react'
import classname from 'classnames/bind'
import { useSelector, useDispatch } from 'react-redux'

// container
import ColorControllerContainer from '../color-controller-container'
import ColorViewContainer from '../color-view-container'

// store
import {
    setColorActive,
    removeColorPicker,
    setColorDeletable,
} from '../../../store/color/color-actions'
import { setBoxColor, setBoxActive } from '../../../store/models/model-actions'
import { showModelMobileMenu } from '../../../store/customizer/customizer-actions'

const cn = classname.bind()

const BoxColorContainer = (props) => {
    const dispatch = useDispatch()
    const { boxActive } = useSelector((state) => state.model)
    const { colors, colorActive, colorPickerActive, colorDeletable } = useSelector(
        (state) => state.color
    )

    const onClickColor = (e, color) => {
        e.stopPropagation()
        dispatch(setColorActive(color))
        dispatch(
            setBoxColor({
                index: boxActive.modelIndex,
                boxIndex: parseInt(boxActive.boxIndex),
                color: color,
            })
        )
        dispatch(setBoxActive({ modelId: null, modelIndex: null, boxId: null, boxIndex: null }))
        dispatch(showModelMobileMenu(false))
    }

    const onClickDeleteColor = (e, index) => {
        e.stopPropagation()
        dispatch(removeColorPicker(index))
    }

    const removeColor = (e) => {
        e.stopPropagation()
        dispatch(setColorDeletable())
    }

    return (
        <div className={cn('colors')}>
            <div className={cn('text-sm', 'mb-4')}>Select Box Color</div>
            <div className={cn('flex', 'flex-row', 'flex-wrap')}>
                <ColorViewContainer
                    colors={colors}
                    colorActive={colorActive}
                    isDeletable={colorDeletable}
                    onClickDelete={onClickDeleteColor}
                    onClickColor={onClickColor}
                />
                <ColorControllerContainer
                    isDeletable={colorDeletable}
                    colorPickerActive={colorPickerActive}
                    onClickRemove={removeColor}
                    colorType={'box'}
                />
            </div>
        </div>
    )
}

export default BoxColorContainer
