export const boxType = (type, size) => {
    switch (type) {
        case '1':
            return `${size}`
        case '1/2':
            return `${size}${type}`
        case '1-1/2':
            return `${size}${type}`
        case '2/3':
            return `${size}${type}`
        case '3/4':
            return `${size}${type}`
        default:
            return `${size}`
    }
}
