export const ADD_MODEL = 'ADD_MODEL'
export const CHANGE_MODEL = 'CHANGE_MODEL'
export const CLEAR_MODEL = 'CLEAR_MODEL'
export const SET_BOX_ACTIVE = 'SET_BOX_ACTIVE'
export const SET_BOX_POSITION = 'SET_BOX_POSITION'
export const SET_MODEL_BORDER_WITH = 'SET_MODEL_BORDER_WITH'
export const SET_MODEL_BORDER_COLOR = 'SET_MODEL_BORDER_COLOR'
export const RELOAD_PERSIS = 'RELOAD_PERSIS'

export const SET_COLORS_ACTIVE = 'SET_COLORS_ACTIVE'
export const SET_COLORS_BORDER_ACTIVE = 'SET_COLORS_BORDER_ACTIVE'
export const SET_COLOR_POSITION = 'SET_COLOR_POSITION'
export const SET_BOX_COLOR = 'SET_BOX_COLOR'
export const ADD_COLOR_PICKER = 'ADD_COLOR_PICKER'
export const REMOVE_COLOR_PICKER = 'REMOVE_COLOR_PICKER'
export const REMOVE_COLOR_BORDER_PICKER = 'REMOVE_COLOR_BORDER_PICKER'
export const SET_COLOR_PICKER_ACTIVE = 'SET_COLOR_PICKER_ACTIVE'
export const SET_COLOR_DELETEABLE = 'SET_COLOR_DELETEABLE'
export const SET_COLOR_BORDER_DELETEABLE = 'SET_COLOR_BORDER_DELETEABLE'

export const CUSTOMIZER_CHANGE_BREAKPOINT = 'CUSTOMIZER_CHANGE_BREAKPOINT'
export const SHOW_MODAL = 'SHOW_MODAL'
export const SHOW_MODEL_MOBILE_MENU = 'SHOW_MODEL_MOBILE_MENU'
