import React from 'react'
import classname from 'classnames/bind'

const cn = classname.bind()

const Modal = ({ show, children, opacity, onClick, zIndex }) => {
    let bgColor = '#00000050'
    let zIndexs = 9999

    if (opacity != null) {
        bgColor = `#000000${opacity}`
    }

    if (opacity != null) {
        zIndexs = zIndex
    }

    return (
        <div
            className={cn('absolute', 'w-full', 'h-full', show ? 'block' : 'hidden')}
            style={{
                zIndex: zIndexs,
                backgroundColor: bgColor,
            }}
            onClick={onClick}
        >
            {children}
        </div>
    )
}

export default Modal
