import React from 'react'
import classname from 'classnames/bind'

const cn = classname.bind()

const Button = ({ text, onClick, className }) => {
    return (
        <div
            className={cn('relative', 'px-4 py-1', 'rounded-sm', 'cursor-pointer', className)}
            onClick={onClick}
        >
            {text}
        </div>
    )
}

export default Button
