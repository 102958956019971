import React from 'react'
import classname from 'classnames/bind'

import { modelBuilder } from '../../utils/model-builder'
const cn = classname.bind()

const ModelMenuViewerContainer = (props) => {
    return (
        <div className={cn('m-4 ')}>
            {modelBuilder(props.boxTable, 'sm', props)}
            <div className={cn('text-center m-2')}>
                <p>{props.name}</p>
            </div>
        </div>
    )
}

export default ModelMenuViewerContainer
