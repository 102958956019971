export const modelData = [
    {
        id: 'rax32',
        name: 'Rax Laci 3x2',
        type: 'box',
        boxTable: [
            ['1', '1', '1'],
            ['1', '1', '1'],
        ],
        color: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff'],
        border: {
            width: 1,
            color: '#000',
        },
        style: {},
    },
    {
        id: 'ra33',
        name: 'Rax Laci 3x3',
        type: 'box',
        boxTable: [
            ['1', '1', '1'],
            ['1', '1', '1'],
            ['1', '1', '1'],
        ],
        color: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff'],
        border: {
            width: 1,
            color: '#000',
        },
        style: {},
    },
    {
        id: 'ra34',
        name: 'Rax Laci 3x4',
        type: 'box',
        boxTable: [
            ['1', '1', '1'],
            ['1', '1', '1'],
            ['1', '1', '1'],
            ['1', '1', '1'],
        ],
        color: [
            '#fff',
            '#fff',
            '#fff',
            '#fff',
            '#fff',
            '#fff',
            '#fff',
            '#fff',
            '#fff',
            '#fff',
            '#fff',
            '#fff',
        ],
        border: {
            width: 1,
            color: '#000',
        },
        style: {},
    },
    {
        id: 'rax43',
        name: 'Rax Laci 4x3',
        type: 'box',
        boxTable: [
            ['1', '1', '1', '1'],
            ['1', '1', '1', '1'],
            ['1', '1', '1', '1'],
        ],
        color: [
            '#fff',
            '#fff',
            '#fff',
            '#fff',
            '#fff',
            '#fff',
            '#fff',
            '#fff',
            '#fff',
            '#fff',
            '#fff',
            '#fff',
        ],
        border: {
            width: 1,
            color: '#000',
        },
        style: {},
    },
    {
        id: 'rax123',
        name: 'Rak Laci 123 Tingkat',
        type: 'box',
        boxTable: [['1'], ['1', '1'], ['1', '1', '1']],
        color: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff'],
        border: {
            width: 1,
            color: '#000',
        },
        style: {},
    },
    {
        id: 'rax234tingkat',
        name: 'Rak Laci 234 Tingkat',
        type: 'box',
        boxTable: [['1'], ['1', '1'], ['1', '1', '1'], ['1', '1', '1']],
        color: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff'],
        border: {
            width: 1,
            color: '#000',
        },
        style: {},
    },
    {
        id: 'rax23',
        name: 'Rak Laci 2x3',
        type: 'box',
        boxTable: [
            ['1', '1'],
            ['1', '1', '1'],
        ],
        color: ['#fff', '#fff', '#fff', '#fff', '#fff'],
        border: {
            width: 1,
            color: '#000',
        },
        style: {},
    },
    {
        id: 'rax23',
        name: 'Rak Laci 2x3',
        type: 'box',
        boxTable: [
            ['1', '1'],
            ['1', '1'],
            ['1', '1'],
        ],
        color: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff'],
        border: {
            width: 1,
            color: '#000',
        },
        style: {},
    },
    {
        id: 'rax24',
        name: 'Rak Laci 2x4',
        type: 'box',
        boxTable: [
            ['1', '1'],
            ['1', '1'],
            ['1', '1'],
            ['1', '1'],
        ],
        color: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff'],
        border: {
            width: 1,
            color: '#000',
        },
        style: {},
    },
    {
        id: 'rax42',
        name: 'Rak Laci 4x2',
        type: 'box',
        boxTable: [
            ['1', '1', '1', '1'],
            ['1', '1', '1', '1'],
        ],
        color: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff'],
        border: {
            width: 1,
            color: '#000',
        },
        style: {},
    },
    {
        id: 'rax22',
        name: 'Rak Laci 2x2',
        type: 'box',
        boxTable: [
            ['1', '1'],
            ['1', '1'],
        ],
        color: ['#fff', '#fff', '#fff', '#fff'],
        border: {
            width: 1,
            color: '#000',
        },
        style: {},
    },
    {
        id: 'rax21',
        name: 'Rak Laci 2x1',
        type: 'box',
        boxTable: [['1', '1']],
        color: ['#fff', '#fff'],
        border: {
            width: 1,
            color: '#000',
        },
        style: {},
    },
    {
        id: 'rax1',
        name: 'Rak Laci Single',
        type: 'box',
        boxTable: [['1']],
        color: ['#fff'],
        border: {
            width: 1,
            color: '#000',
        },
        style: {},
    },
    {
        id: 'rax3x1',
        name: 'Rak Laci 3x1',
        type: 'box',
        boxTable: [['1', '1', '1']],
        color: ['#fff', '#fff', '#fff'],
        border: {
            width: 1,
            color: '#000',
        },
        style: {},
    },
    {
        id: 'rax12tingkat',
        name: 'Rak Laci 12 Tingkat',
        type: 'box',
        boxTable: [['1'], ['1', '1']],
        color: ['#fff', '#fff', '#fff'],
        border: {
            width: 1,
            color: '#000',
        },
        style: {},
    },
    {
        id: 'rax12',
        name: 'Rak Laci 1x2',
        type: 'box',
        boxTable: [['1'], ['1']],
        color: ['#fff', '#fff'],
        border: {
            width: 1,
            color: '#000',
        },
        style: {},
    },
    {
        id: 'rax13',
        name: 'Rak Laci 1x3',
        type: 'box',
        boxTable: [['1'], ['1'], ['1']],
        color: ['#fff', '#fff', '#fff'],
        border: {
            width: 1,
            color: '#000',
        },
        style: {},
    },
    {
        id: 'rax14',
        name: 'Rak Laci 1x4',
        type: 'box',
        boxTable: [['1'], ['1'], ['1'], ['1']],
        color: ['#fff', '#fff', '#fff', '#fff'],
        border: {
            width: 1,
            color: '#000',
        },
        style: {},
    },
    {
        id: 'rax32dvd',
        name: 'Rak Laci 3+2 DVD',
        type: 'box',
        boxTable: [
            ['1', '1-1/2', '1'],
            ['1', '1', '1'],
        ],
        color: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff'],
        border: {
            width: 1,
            color: '#000',
        },
        style: {},
    },
    {
        id: 'rax2dvd',
        name: 'Rak Laci 2 DVD',
        type: 'box',
        boxTable: [['1', '1-1/2', '1']],
        color: ['#fff', '#fff', '#fff'],
        border: {
            width: 1,
            color: '#000',
        },
        style: {},
    },
    {
        id: 'rax34samping',
        name: 'Rax 3+4 Samping',
        type: 'box',
        boxTable: [
            ['3/4', '3/4', '3/4', '3/4'],
            ['1', '1', '1'],
        ],
        border: {
            width: 1,
            color: '#000',
        },
        color: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff'],
        style: {},
    },
    {
        id: 'rax23atas',
        name: 'Rax 2+3 Atas',
        type: 'box',
        boxTable: [
            ['2/3', '2/3', '2/3'],
            ['1', '1'],
        ],
        border: {
            width: 1,
            color: '#000',
        },
        color: ['#fff', '#fff', '#fff', '#fff', '#fff'],
        style: {},
    },
    {
        id: 'rax43atas',
        name: 'Rax 4+3 Atas',
        type: 'box',
        boxTable: [
            ['2/3', '2/3', '2/3'],
            ['1', '1'],
            ['1', '1'],
        ],
        border: {
            width: 1,
            color: '#000',
        },
        color: ['#fff', '#fff', '#fff', '#fff', '#fff'],
        style: {},
    },
    {
        id: 'rax64',
        name: 'Rax 6+4',
        type: 'box',
        boxTable: [
            ['3/4', '3/4', '3/4', '3/4'],
            ['1', '1', '1'],
            ['1', '1', '1'],
        ],
        border: {
            width: 1,
            color: '#000',
        },
        color: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff'],
        style: {},
    },
    {
        id: 'rax3plus2atas',
        name: 'Rax 3+2 Atas',
        type: 'box',
        boxTable: [['1/2', '1/2'], ['1'], ['1'], ['1']],
        border: {
            width: 1,
            color: '#000',
        },
        color: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff'],
        style: {},
    },
    {
        id: 'rax1plus2atas',
        name: 'Rax 1+2 Atas',
        type: 'box',
        boxTable: [['1/2', '1/2'], ['1']],
        border: {
            width: 1,
            color: '#000',
        },
        color: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff'],
        style: {},
    },
]
