import React, { useEffect } from 'react'
import classname from 'classnames/bind'
import layoutStyle from './layout.module.css'
import { useDispatch } from 'react-redux'

// store
import { changeThemeBreakPoint } from '../store/customizer/customizer-actions'

// data
import { theme } from '../data/theme-data'

const cn = classname.bind()

const Layout = ({ children }) => {
    const dispatch = useDispatch()

    useEffect(() => {
        mediaQueryChanged()

        typeof window === 'object' && window.addEventListener('resize', mediaQueryChanged)

        return () => {
            typeof window === 'object' && window.removeEventListener('resize', mediaQueryChanged)
        }
    }, [])

    const mediaQuery = {
        mediaSm:
            typeof window === 'object' && window.matchMedia(`(min-width: ${theme.screens.sm})`),
        mediaMd:
            typeof window === 'object' && window.matchMedia(`(min-width: ${theme.screens.md})`),
        mediaLg:
            typeof window === 'object' && window.matchMedia(`(min-width: ${theme.screens.lg})`),
        mediaXl:
            typeof window === 'object' && window.matchMedia(`(min-width: ${theme.screens.xl})`),
        mediaXxl:
            typeof window === 'object' && window.matchMedia(`(min-width: ${theme.screens.xxl})`),
    }

    const mediaQueryChanged = () => {
        if (mediaQuery.mediaXxl.matches) {
            dispatch(changeThemeBreakPoint('xxl'))
        } else if (mediaQuery.mediaXl.matches) {
            dispatch(changeThemeBreakPoint('xl'))
        } else if (mediaQuery.mediaLg.matches) {
            dispatch(changeThemeBreakPoint('lg'))
        } else if (mediaQuery.mediaMd.matches) {
            dispatch(changeThemeBreakPoint('md'))
        } else if (mediaQuery.mediaSm.matches) {
            dispatch(changeThemeBreakPoint('sm'))
        } else {
            dispatch(changeThemeBreakPoint('default'))
        }
    }

    return (
        <main className={cn(layoutStyle.main, 'bg-primary flex flex-row overflow-hidden')}>
            {children}
        </main>
    )
}

export default Layout
