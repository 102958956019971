import {
    ADD_MODEL,
    CLEAR_MODEL,
    SET_BOX_ACTIVE,
    SET_BOX_POSITION,
    SET_BOX_COLOR,
    CHANGE_MODEL,
    SET_MODEL_BORDER_WITH,
    SET_MODEL_BORDER_COLOR,
    RELOAD_PERSIS,
} from '../actions'

import { configureStore } from '../store'

export const addModel = (data) => {
    return {
        type: ADD_MODEL,
        data,
    }
}

export const changeModel = (data) => {
    return {
        type: CHANGE_MODEL,
        data,
    }
}

export const clearModel = () => {
    return {
        type: CLEAR_MODEL,
    }
}

export const reloadModel = () => {
    let persistor = configureStore().persistor

    persistor
        .purge()
        .then((value) => {
            window.location.reload()
        })
        .catch((e) => console.log(e))

    return {
        type: RELOAD_PERSIS,
    }
}

export const setBoxActive = ({ modelId, modelIndex, boxId, boxIndex }) => {
    return {
        type: SET_BOX_ACTIVE,
        modelId,
        modelIndex,
        boxId,
        boxIndex,
    }
}

export const setBoxPosition = ({ index, top, left }) => {
    return {
        type: SET_BOX_POSITION,
        index,
        top,
        left,
    }
}

export const setBoxColor = ({ index, boxIndex, color }) => {
    return {
        type: SET_BOX_COLOR,
        index,
        boxIndex,
        color,
    }
}

export const setModelBorderWidth = (borderWidth, modelIndex) => {
    return {
        type: SET_MODEL_BORDER_WITH,
        borderWidth,
        modelIndex,
    }
}

export const setModelBorderColor = (borderColor, modelIndex) => {
    return {
        type: SET_MODEL_BORDER_COLOR,
        borderColor,
        modelIndex,
    }
}
