import React from 'react'
import classname from 'classnames/bind'
import { useDispatch } from 'react-redux'

// store
import { showModal } from '../../store/customizer/customizer-actions'
import { changeModel } from '../../store/models/model-actions'
import { showModelMobileMenu } from '../../store/customizer/customizer-actions'

// component
import Center from '../../component/Center'
import Button from '../../component/Button'

// utils
import { newModelData } from '../../utils/mutations'

const cn = classname.bind()

const ChangeModelModal = ({ modalItem }) => {
    const dispatch = useDispatch()

    const onClikButtonYes = () => {
        dispatch(changeModel(newModelData(modalItem)))
        dispatch(showModal({ modalType: null, modalItem: null }))
        dispatch(showModelMobileMenu(false))
    }

    const onClikButtonNo = () => {
        dispatch(showModal({ modalType: null, modalItem: null }))
    }

    return (
        <Center>
            <div className={cn('bg-white', 'rounded-md', 'p-4', 'flex', 'flex-col')}>
                <div className={cn('text-center')}>Ubah Model ?</div>
                <div
                    className={cn(
                        'flex',
                        'flex-row',
                        'justify-between',
                        'flex-nowrap',
                        'mt-4',
                        'space-x-4'
                    )}
                >
                    <Button
                        text={'No'}
                        onClick={onClikButtonNo}
                        className={cn('bg-red-500', 'text-white')}
                    />
                    <Button
                        text={'Yes'}
                        onClick={onClikButtonYes}
                        className={cn('bg-blue-500', 'text-white')}
                    />
                </div>
            </div>
        </Center>
    )
}

export default ChangeModelModal
