export const mediaMinWidth = (breakpoint) => {
    return {
        sm:
            breakpoint === 'sm' ||
            breakpoint === 'md' ||
            breakpoint === 'lg' ||
            breakpoint === 'xl' ||
            breakpoint === 'xxl',
        md:
            breakpoint === 'md' ||
            breakpoint === 'lg' ||
            breakpoint === 'xl' ||
            breakpoint === 'xxl',
        lg: breakpoint === 'lg' || breakpoint === 'xl' || breakpoint === 'xxl',
        xl: breakpoint === 'xl' || breakpoint === 'xxl',
        xxl: breakpoint === 'xxl',
    }
}

export const mediaWidht = (breakpoint) => {
    return {
        default: breakpoint === 'default',
        sm: breakpoint === 'sm',
        md: breakpoint === 'md',
        lg: breakpoint === 'lg',
        xl: breakpoint === 'xl',
        xxl: breakpoint === 'xxl',
    }
}
