import React from 'react'
import classname from 'classnames/bind'
import { useSelector } from 'react-redux'

// component
import Center from '../../component/Center'

// container
import ModelContainer from '../model/model-container'

// utils
import { mediaMinWidth } from '../../utils/breakpoints'

const cn = classname.bind()

const EditorViewContainer = ({ isOverCurrent, bgColor }) => {
    const { layout } = useSelector((state) => state.model)
    const { breakpoint } = useSelector((state) => state.customizer)

    let message = isOverCurrent ? 'Drop Now' : 'Drag Model Here'
    let containerClass = ''

    if (!mediaMinWidth(breakpoint).lg) {
        containerClass = 'flex items-center justify-center'
        message = 'Select Model'
    }

    const renderModel = layout.map((item, index) => (
        <ModelContainer key={index} index={index} {...item} />
    ))

    return (
        <div
            id={'editor'}
            className={cn(
                'absolute w-full h-full overflow-hidden',
                !isOverCurrent ? bgColor : 'bg-green-100',
                containerClass
            )}
        >
            {layout.length == 0 && (
                <Center>
                    <p
                        className={cn(
                            'lg:text-5xl text-3xl',
                            !isOverCurrent ? 'text-gray-200' : 'text-green-300'
                        )}
                    >
                        {message}
                    </p>
                </Center>
            )}
            {renderModel}
        </div>
    )
}
export default EditorViewContainer
