import React from 'react'
import classname from 'classnames/bind'
import { useSelector, useDispatch } from 'react-redux'
import { useDrag } from 'react-dnd'

// store
import { showModal } from '../../store/customizer/customizer-actions'
import { addModel } from '../../store/models/model-actions'

// container
import ModelMenuViewContainer from './model-menu-view-container'

// utils
import { mediaMinWidth } from '../../utils/breakpoints'
import { newModelData } from '../../utils/mutations'

const cn = classname.bind()

const ModelMenuContainer = (props) => {
    const dispatch = useDispatch()
    const { layout, models } = useSelector((state) => state.model)
    const { breakpoint } = useSelector((state) => state.customizer)

    const Item = (data, index) => {
        // Make item gui components dragable
        const [, drag] = useDrag({
            item: {
                type: data.type,
                ...data,
            },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        })

        return (
            <div ref={drag} key={index} className={'relative cursor-move'}>
                <ModelMenuViewContainer {...data} />
                <div
                    className={cn('w-full h-full absolute top-0')}
                    onClick={() => onCLickItem(data)}
                ></div>
            </div>
        )
    }

    const onCLickItem = (item) => {
        if (!mediaMinWidth(breakpoint).lg) {
            if (layout.length != 0) {
                dispatch(showModal({ modalType: 'changeModel', modalItem: item }))
            } else {
                dispatch(addModel(newModelData(item)))
            }
        }
    }

    const renderItems = models.map((item, index) => {
        return Item(item, index)
    })

    let containerClass = cn('flex flex-col items-center')

    if (!mediaMinWidth(breakpoint).lg) {
        containerClass = cn('flex flex-row overflow-scroll px-6')
    }

    return (
        <div className={cn('model-container')}>
            <div className={cn('lg:text-center text-2xl font-bold mx-8 my-8 lg:mx-0')}>
                {mediaMinWidth(breakpoint).lg ? <h2>Drag Model</h2> : <h2>Select Model</h2>}
            </div>
            <div className={cn(containerClass)}>{renderItems}</div>
        </div>
    )
}

export default ModelMenuContainer
