import React from 'react'
import classname from 'classnames/bind'

const cn = classname.bind()

const InputRange = ({ value, min, max, id, onChange }) => {
    return (
        <div className={cn('w-full')}>
            <input
                className={cn('w-full')}
                type="range"
                min={min}
                max={max}
                value={value}
                id={id}
                onChange={onChange}
            />
        </div>
    )
}

export default InputRange
