import React from 'react'
import classname from 'classnames/bind'
import { useSelector, useDispatch } from 'react-redux'

// store
import { setModelBorderWidth } from '../../../store/models/model-actions'

// component
import InputRange from '../../../component/InputRange'

// container
import ColorViewContainer from '../color-view-container'
import ColorControllerContainer from '../color-controller-container'

// utils
import { isNotNull } from '../../../utils/utils'

// store
import {
    setColorBorderActive,
    setColorBorderDeletable,
    removeColorBorderPicker,
} from '../../../store/color/color-actions'
import { setModelBorderColor, setBoxActive } from '../../../store/models/model-actions'
import { showModelMobileMenu } from '../../../store/customizer/customizer-actions'

const cn = classname.bind()

const BorderContainer = (props) => {
    const dispatch = useDispatch()
    const { layout, boxActive } = useSelector((state) => state.model)
    const {
        colorsBorder,
        colorBorderActive,
        colorPickerActive,
        colorBorderDeletable,
    } = useSelector((state) => state.color)

    const onChangeBorder = (e) => {
        e.stopPropagation()
        dispatch(setModelBorderWidth(e.target.value, parseInt(boxActive.modelIndex)))
    }

    const onClickColor = (e, color) => {
        e.stopPropagation()
        dispatch(setColorBorderActive(color))
        dispatch(setModelBorderColor(color, boxActive.modelIndex))
        dispatch(setBoxActive({ modelId: null, modelIndex: null, boxId: null, boxIndex: null }))
        dispatch(showModelMobileMenu(false))
    }

    const onClickDeleteColor = (e, index) => {
        e.stopPropagation()
        dispatch(removeColorBorderPicker(index))
    }

    const removeColor = (e) => {
        e.stopPropagation()
        dispatch(setColorBorderDeletable())
    }

    const renderForm = isNotNull(layout) && isNotNull(boxActive.modelIndex) && (
        <>
            <div className={cn('text-sm', 'mb-4')}>Set Border Width</div>
            <InputRange
                id={'borderWidth'}
                value={layout[parseInt(boxActive.modelIndex)].border.width}
                min={1}
                max={20}
                onChange={onChangeBorder}
            />
        </>
    )

    const renderColors = isNotNull(layout) && isNotNull(boxActive.modelIndex) && (
        <>
            <div className={cn('text-sm', 'mb-4')}>Select Border Color</div>
            <div className={cn('flex', 'flex-row', 'flex-wrap')}>
                <ColorViewContainer
                    colors={colorsBorder}
                    colorActive={colorBorderActive}
                    isDeletable={colorBorderDeletable}
                    onClickDelete={onClickDeleteColor}
                    onClickColor={onClickColor}
                />
                <ColorControllerContainer
                    isDeletable={colorBorderDeletable}
                    colorPickerActive={colorPickerActive}
                    onClickRemove={removeColor}
                    colorType={'border'}
                />
            </div>
        </>
    )

    return (
        <div className={cn('mt-4')}>
            {renderForm}
            {renderColors}
        </div>
    )
}

export default BorderContainer
