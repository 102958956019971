import React from 'react'
import classname from 'classnames/bind'

import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg'
import { ReactComponent as MinusIcon } from '../../assets/icons/minus.svg'
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg'

import { useDispatch } from 'react-redux'
import { setColorPickerActive, setColorDeletable } from '../../store/color/color-actions'

const cn = classname.bind()

const ColorControllerContainer = ({ isDeletable, colorPickerActive, onClickRemove, colorType }) => {
    const dispatch = useDispatch()

    const addColor = (e) => {
        e.stopPropagation()
        dispatch(setColorPickerActive(colorType))
    }

    let colorBoxStyle = {
        width: 40,
        height: 40,
    }

    console.log(colorPickerActive, colorType)

    return (
        <div className={cn('flex flex-row')}>
            <div
                className={cn(
                    'm-1 flex items-center justify-center border border-gray-400 cursor-pointer rounded-md'
                )}
                onClick={addColor}
                style={colorBoxStyle}
            >
                <PlusIcon />
            </div>
            <div
                className={cn(
                    'm-1 flex items-center justify-center border-gray-400 cursor-pointer rounded-md',
                    !isDeletable && 'border',
                    isDeletable && 'bg-red-600'
                )}
                style={colorBoxStyle}
                onClick={onClickRemove}
            >
                {isDeletable ? <CloseIcon /> : <MinusIcon />}
            </div>
        </div>
    )
}

export default ColorControllerContainer
