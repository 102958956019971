import React, { useState } from 'react'
import classname from 'classnames/bind'
import * as htmlToImage from 'html-to-image'
import * as FileSaver from 'file-saver'
import { useDrop } from 'react-dnd'
import { useDispatch } from 'react-redux'

// store
import { addModel, clearModel, setBoxActive, reloadModel } from '../../store/models/model-actions'
import { setColorActive } from '../../store/color/color-actions'
import { showModelMobileMenu } from '../../store/customizer/customizer-actions'

// component
import Button from '../../component/Button'

// container
import EditorViewContainer from './editor-view-container'

// utils
import { newModelData } from '../../utils/mutations'

// data
import { ItemTypes } from '../../itemTypes'

const cn = classname.bind()

const EditorContainer = (props) => {
    const dispatch = useDispatch()

    // state
    const [posX, setX] = useState(0)
    const [posY, setY] = useState(0)
    const [bgColor, setBgColor] = useState('bg-primary')

    // DROPABLE LAYOUT
    const greedy = false
    const [{ canDrop, isOver, isOverCurrent }, drop] = useDrop({
        accept: [ItemTypes.BOX],
        drop: (item, monitor) => {
            const didDrop = monitor.didDrop()
            if (didDrop && !greedy) {
                return
            }
            handleDrop(item)
        },

        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
            isOverCurrent: monitor.isOver({ shallow: true }),
        }),

        hover: (item, monitor) => {
            // get x, y position mouse from layout element
            let obj = document.getElementById('layout')
            let x = monitor.getClientOffset().x
            let y = monitor.getClientOffset().y
            x -= obj.getBoundingClientRect().x
            y -= obj.getBoundingClientRect().y
            setX(x)
            setY(y)
        },
    })

    const handleDrop = (item) => {
        dispatch(addModel(newModelData(item, posX, posY)))
    }

    const onClikClear = () => {
        dispatch(clearModel())
    }

    const onClikReload = () => {
        dispatch(reloadModel())
    }

    const onClikExport = () => {
        setBgColor('bg-white')
        let node = document.getElementById('editor')

        htmlToImage
            .toJpeg(node)
            .then(function (dataUrl) {
                FileSaver.saveAs(dataUrl, `rax-${Date.now()}.jpg`, {
                    height: node.clientHeight,
                    width: node.clientWidth,
                })
                setBgColor('bg-gray-100')
            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error)
            })
    }

    const onClickLayout = (e) => {
        if (e.nativeEvent.target.id === 'editor') {
            dispatch(setBoxActive({ modelId: null, modelIndex: null, boxId: null, boxIndex: null }))
            dispatch(setColorActive(null))
            dispatch(showModelMobileMenu(false))
        }
    }

    return (
        <div
            ref={drop}
            id={'layout'}
            className={cn('absolute inset-0 overflow-hidden bg-gray-200')}
            onClick={onClickLayout}
        >
            <div className={cn('absolute w-max m-4 z-50 cursor-pointer text-center')}>
                <Button
                    text={'Reload'}
                    onClick={onClikReload}
                    className={cn('bg-red-500 text-white mb-2')}
                />

                <Button
                    text={'Clear'}
                    onClick={onClikClear}
                    className={cn('bg-red-500 text-white')}
                />

                <Button
                    text={'Export to Jpg'}
                    onClick={onClikExport}
                    className={cn('bg-blue-500 text-white mt-2')}
                />
            </div>
            <EditorViewContainer isOverCurrent={isOverCurrent} bgColor={bgColor} />
        </div>
    )
}

export default EditorContainer
