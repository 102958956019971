import React, { useState } from 'react'
import classname from 'classnames/bind'
import Center from '../../component/Center'
import Button from '../../component/Button'

import { PhotoshopPicker, ChromePicker } from 'react-color'

import { useDispatch, useSelector } from 'react-redux'
import { addColorPicker, setColorPickerActive } from '../../store/color/color-actions'
import { mediaWidht } from '../../utils/breakpoints'

const cn = classname.bind()

const ColorPickerContainer = () => {
    const dispatch = useDispatch()

    const { colorPicker, colorPickerActive } = useSelector((state) => state.color)
    const { breakpoint } = useSelector((state) => state.customizer)

    const [colorByPicker, setColorByPicker] = useState(colorPicker)

    const handleChangeColor = (color, event) => {
        setColorByPicker(color.hex)
    }

    const acceptedColorPicker = () => {
        dispatch(addColorPicker(colorByPicker, colorPickerActive.type))
        dispatch(setColorPickerActive(colorPickerActive.type))
    }

    const onCancelColorPicker = () => {
        dispatch(setColorPickerActive(colorPickerActive.type))
    }

    return (
        <Center>
            {mediaWidht(breakpoint).default ? (
                <div className={cn('flex', 'flex-col')}>
                    <ChromePicker color={colorByPicker} onChange={handleChangeColor} />
                    <div className={cn('flex', 'flex-col', 'mt-4', 'space-y-4', 'text-center')}>
                        <Button
                            text={'Cancel'}
                            onClick={onCancelColorPicker}
                            className={cn('bg-red-500', 'text-white')}
                        />
                        <Button
                            text={'Ok'}
                            onClick={acceptedColorPicker}
                            className={cn('bg-blue-500', 'text-white')}
                        />
                    </div>
                </div>
            ) : (
                <PhotoshopPicker
                    color={colorByPicker}
                    onChange={handleChangeColor}
                    onAccept={acceptedColorPicker}
                    onCancel={onCancelColorPicker}
                />
            )}
        </Center>
    )
}

export default ColorPickerContainer
